import React from 'react';

const ArrowTopIcon = ({color, className}) => (
    <svg width="25" height="19" viewBox="0 0 25 19" fill="none" className={className}
         xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.25075 18.7171H23.7514C23.9792 18.7164 24.2025 18.6535 24.3973 18.5354C24.592 18.4172 24.7508 18.2481 24.8567 18.0464C24.9625 17.8446 25.0113 17.6179 24.9978 17.3905C24.9843 17.163 24.9091 16.9436 24.7802 16.7558L13.5299 0.505327C13.0636 -0.168442 11.9411 -0.168442 11.4735 0.505327L0.223223 16.7558C0.0930009 16.9432 0.0166353 17.1628 0.00242372 17.3906C-0.0117879 17.6183 0.0366981 17.8457 0.142614 18.0478C0.248529 18.25 0.407824 18.4193 0.603189 18.5373C0.798555 18.6553 1.02252 18.7175 1.25075 18.7171Z"
            fill="black" fillOpacity="0.7"/>
    </svg>


);

export default ArrowTopIcon;