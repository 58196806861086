/* global bootstrap: false */
(() => {
  'use strict'

  const tooltipTriggerList = Array.from(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  tooltipTriggerList.forEach(tooltipTriggerEl => {
    console.log('testing')
    new bootstrap.Tooltip(tooltipTriggerEl)
  })
})()
