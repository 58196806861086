import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './auth.slice';
import { usersReducer } from './users.slice';
import newCampNameReducer from '../modules/campaign/redux/newCampNameSlice'
import merchantCenterAccountReducer from '../modules/campaign/redux/merchantCenterAccount'
import customerMetricsReducer from '../modules/campaign/redux/customerMetrics'
import systemCustomerMetricsReducer from '../modules/campaign/redux/system/systemCustomerMetrics'
import campaignMetricsReducer from '../modules/campaign/redux/campaignMetrics'
import campaignsReducer from '../modules/campaign/redux/campaigns'
import systemCampaignsReducer from '../modules/campaign/redux/system/systemCampaigns'
import recommendationsReducer from '../modules/campaign/redux/recommendations'
import campaignRecommendationsReducer from '../modules/campaign/redux/campaignRecommendations'
import assetGroupsReducer from '../modules/campaign/redux/assetGroups'
import googleAccountReducer from "../modules/campaign/redux/googleAccount";
import customerReducer from "../modules/campaign/redux/customer";

export * from './auth.slice';
export * from './users.slice';

export const store = configureStore({
    reducer: {
        auth: authReducer,
        users: usersReducer,
        newCampName: newCampNameReducer,
        merchantCenterAccount: merchantCenterAccountReducer,
        customer: customerReducer,
        customerMetrics: customerMetricsReducer,
        systemCustomerMetrics: systemCustomerMetricsReducer,
        campaignMetrics: campaignMetricsReducer,
        campaigns: campaignsReducer,
        systemCampaigns: systemCampaignsReducer,
        recommendations: recommendationsReducer,
        assetGroups: assetGroupsReducer,
        googleAccount: googleAccountReducer,
        campaignRecommendations: campaignRecommendationsReducer,
    },
});