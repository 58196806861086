import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Sidebar} from "primereact/sidebar";
import {Button} from "primereact/button";
import logo from "../../assets/images/logo.png";
import {ExpandMoreOutlined} from "@material-ui/icons";
import {NavLink, useLocation} from "react-router-dom";
import navItems from "../../navigation/items";
import campaignNavItems from "../../modules/campaign/navigation/items";
import {Typography} from "@material-ui/core";
import {Toast} from "primereact/toast";
import {Menu} from "primereact/menu";
import {Avatar} from "primereact/avatar";
import {useDispatch, useSelector} from "react-redux";
import {authActions} from "../../redux";
import axios from "axios";
import {SetGoogleAccount} from "../../modules/campaign/redux/googleAccount";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import GoogleAccountConnectButton from "../../components/backend_connect_with_google/GoogleAccountConnectButton";
import {useNavigate} from "react-router";

const navItemsAll = [
    ...navItems,
    ...campaignNavItems,

];
const MobileSidebar = ({ formData, setFormData }) => {
    const [visible, setVisible] = useState(false);
    useEffect(() => {
        const savedFormData = localStorage.getItem('formData');
        if (savedFormData) {
            setFormData(JSON.parse(savedFormData));
            setInput(
                new Intl.DateTimeFormat('en-GB', {
                    day: 'numeric',
                    month: 'long'
                }).format(formData.from === '' ? new Date() : new Date(formData.from)) +
                ' - ' +
                new Intl.DateTimeFormat('en-GB', {
                    day: 'numeric',
                    month: 'long'
                }).format(formData.to === '' ? new Date() : new Date(formData.to))
            );
        }
    }, [setFormData]);
    const [showCustomCalendar, setShowCustomCalendar] = useState(false);
    const [dateRange, setDateRange] = useState({startDate: new Date(), endDate: new Date()});
    // Save data to localStorage whenever formData changes
    useEffect(() => {
        localStorage.setItem('formData', JSON.stringify(formData));
    }, [formData]);
    const authUser = useSelector((x) => x.auth.user);
    const dispatch = useDispatch();
    // const [selectedOption, setSelectedOption] = useState(null);
    const [date, setDate] = useState(null);
    const [calendarVisible, setCalendarVisible] = useState(false);

    const [activeInput, setActiveInput] = useState(null);
    const [input, setInput] = useState('');
    const menuLeft = useRef(null);
    const toast = useRef(null);
    const baseUrl = process.env.REACT_APP_ADMIN_API
    const user = JSON.parse(localStorage.getItem('user'));
    const googleUser = JSON.parse(localStorage.getItem('google_account'));
    const source = localStorage.getItem('source');
    const [is_connected, setIsConnected] = useState(source === 'google' ? false : true);
    if (!source) {
        localStorage.setItem('source', 'google');
    }

    const handleSwitchChange = (e) => {
        if (is_connected) {
            localStorage.setItem('source', 'google');
        } else {
            localStorage.setItem('source', 'system');
        }

        setIsConnected(!is_connected)
        window.location.reload();
    };

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [formattedDateRange, setFormattedDateRange] = useState({
        from: '',
        to: '',
    });


    const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

    function formatDateToYYYYMMDD(date) {

        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateRangeApply = (range) => {
        const formattedFrom = formatDateToYYYYMMDD(range.startDate);
        const formattedTo = formatDateToYYYYMMDD(range.endDate);

        setFormattedDateRange({from: formattedFrom, to: formattedTo});
        setFormData({...formData, from: formattedFrom, to: formattedTo});
        setDateRange(range);
        toggleDropdown();
        window.location.reload();
    };
    const items = [
        {
            label: 'Account',
            icon: 'pi pi-user',
        },
        {
            separator: true
        },
        {
            label: 'Logout',
            icon: 'pi pi-sign-out',
            command: () => {
                logout()
            }
        },
        googleUser ? {
            label: 'Disconnect From Google Account',
            icon: 'pi pi-sign-out',
            command: () => {
                disconnectFromGoogleAccount()
            }
        } : {
            label: 'Connect with Google Account',
            icon: 'pi pi-sign-out',
            command: () => {
                setModal(true)
            }
        },
        {
            label: 'Choose Account',
            icon: 'pi pi-sign-out',
            url: '/choose-customer'
        },
        {
            label: 'Download Scripts',
            icon: 'pi pi-download',
            url: '/download-script',
            target: 'blank'
        }
    ];
    const handleSelectChange = (selectedOption) => {

        // setFormData({...formData, selectedOption: selectedOption});
        setFormData({...formData, selectedOption});
        if (selectedOption.value === '2' || selectedOption.value === '3') {
            window.location.reload();
        }
    };

    const handleApply = () => {
        // Set the date range in the form inputs and hide the custom calendar
        setFormData({...formData, from: dateRange.startDate, to: dateRange.endDate});
        setShowCustomCalendar(false);
        toggleDropdown();
    };

    const handleCancel = () => {
        // Hide the custom calendar without changing the form inputs
        setShowCustomCalendar(false);
        toggleDropdown();
    };
    const onInputChange = (event) => {
        // Handle input changes
        setFormData({...formData, [event.target.name]: event.target.value});
    };
    const handleCalendarChange = (value) => {
        setDate(value);
        // Get the local time zone offset in minutes
        const timeZoneOffset = value.value.getTimezoneOffset() * 60000; // Convert to milliseconds
        // Adjust the date by the time zone offset and format it to 'YYYY-MM-DD'
        const formattedDate = new Date(value.value - timeZoneOffset).toISOString().split('T')[0];
        setFormData({...formData, [activeInput]: formattedDate});
        setCalendarVisible(false); // Hide calendar after date selection
    };


    const handleInputFocus = (inputName) => {
        setActiveInput(inputName);
        setCalendarVisible(true); // Show calendar when input is focused


    };

    const handleInputClick = (field) => {
        // setActiveInput(inputName);
        setShowCustomCalendar(!showCustomCalendar);
    };

    const logout = () => dispatch(authActions.logout());
    const connectWithGoogleAccount = () => setModal(true);

    async function disconnectFromGoogleAccount() {
        try {

            // Make a GET request to your Laravel API getAuthUrl endpoint
            const response = await axios.post(baseUrl + '/v1/google-account/disconnect-from-account',
                { email: googleUser !== null ? googleUser.email : '' },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${user.token}`,
                    },
                }).then((response) => {
                toast.current.show({severity: 'success', summary: 'Success', detail: 'Logged out from Google successfully'});
                localStorage.removeItem('google_account');
                localStorage.setItem('is_trying_to_connect_with_google', 0);
                localStorage.setItem('customer', null);
                dispatch(SetGoogleAccount([]))
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);
            }).catch((error) => {

                toast.current.show({severity:'error', summary: 'Error', detail: error.response.data.message});
                console.error('Error fetching data:', error.response.data.message);
                localStorage.setItem('is_trying_to_connect_with_google', 0);
                setTimeout(() => {
                    window.location.href = '/';
                }, 2000);

            });
            const authUrl = response.data;

            // Redirect the user to the Google login page
            window.location.href = authUrl;
        } catch (error) {
            // Handle error
            console.error(error);
        }
    }



    const location = useLocation();
    // const navigate = useNavigate();
    // const handleGoogleCallback = useCallback(async (authCode) => {
    //     localStorage.setItem('is_trying_to_connect_with_google', 1);
    //
    //     try {
    //         const response = await axios.post(baseUrl + '/v1/google-account/connect-to-account', { auth_code: authCode }, {
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 Authorization: `Bearer ${user.token}`,
    //             },
    //         });
    //
    //         const token = response.data;
    //         await localStorage.setItem('google_account', JSON.stringify(response.data.google_account));
    //
    //         dispatch(SetGoogleAccount(response.data));
    //
    //         // Ensure this line executes after the localStorage.setItem
    //         navigate('/choose-customer');
    //     } catch (error) {
    //         console.error(error);
    //         localStorage.setItem('is_trying_to_connect_with_google', 3);
    //     }
    // }, [baseUrl]);
    // useEffect(() => {
    //     const searchParams = new URLSearchParams(location.search);
    //     const authCode = searchParams.get('code');
    //
    //     if (authCode) {
    //         handleGoogleCallback(authCode);
    //     }
    // }, [location.search, handleGoogleCallback]);
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);

return (
    <div className={'mobile-sidebar'}>
        <Sidebar visible={visible} onHide={() => setVisible(false)}>
            <a href="/" className="align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none">
                <img src={logo} alt="google" className={'sidebar-logo-image'}/>
            </a>
            <ul className="nav nav-pills flex-column mb-auto">
                {navItemsAll.map((item) => (
                    <li className="nav-item" key={item.id}>
                        {item.subItems ? (
                            // Render menu item with sub-items
                            <>
                                <div
                                    className="nav-link link-body-emphasis"
                                    data-bs-toggle="collapse"
                                    data-bs-target={`#${item.title.replace(/\s/g, '')}SubMenu`}
                                >
                                    {item.icon}
                                    {item.link ?
                                        <NavLink to={item.link} className="nav-link sidebar-nav d-inline-block"
                                                 style={{paddingLeft: 0}}>
                                            <span className="sidebar-title">{item.title}</span>
                                        </NavLink> :
                                        <span className="sidebar-title">{item.title}</span>
                                    }
                                    <ExpandMoreOutlined className="ms-auto expanded-arrow"/>
                                </div>
                                <div id={`${item.title.replace(/\s/g, '')}SubMenu`} className="collapse">
                                    <ul className="nav flex-column ps-3 sub-menu">
                                        {item.subItems.map((subItem) => (
                                            <li className="nav-item" key={subItem.id}>
                                                <NavLink to={subItem.link} className="nav-link sub-item">
                                                     {subItem.title}
                                                </NavLink>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </>
                        ) : (
                            // Render regular menu item
                            <NavLink to={item.link} className="nav-link sidebar-nav">
                                {item.icon}
                                <span className="sidebar-title">{item.title}</span>
                            </NavLink>
                        )}
                    </li>
                ))}
                <li className="nav-item" key={'account'}>
                    <>
                        <div
                            className="nav-link link-body-emphasis"
                            data-bs-toggle="collapse"
                            data-bs-target={`#AccountSubMenu`}
                        >
                            <i className="pi pi pi-user sidebar-svg"></i>
                            {/*{item.icon}*/}
                            {/*{item.link ?*/}
                            {/*    <NavLink to={item.link} className="nav-link sidebar-nav d-inline-block"*/}
                            {/*             style={{paddingLeft: 0}}>*/}
                            {/*        <span className="sidebar-title">{item.title}</span>*/}
                            {/*    </NavLink> :*/}
                                <span className="sidebar-title">Account</span>
                            {/*}*/}
                            <ExpandMoreOutlined className="ms-auto expanded-arrow"/>
                        </div>
                        <div id={`AccountSubMenu`} className="collapse">
                            <ul className="nav flex-column ps-3 sub-menu">
                                {/*{item.subItems.map((subItem) => (*/}
                                    <li className="nav-item" key={'account'}>

                                        <NavLink to={'#'} className="nav-link sub-item">
                                             Account
                                        </NavLink>
                                    </li>
                                    <li className="nav-item" key={'logout'}>
                                        <button onClick={logout} className="nav-link sub-item">
                                            Logout
                                        </button>
                                    </li>
                                {googleUser ?
                                    <li className="nav-item" key={'Disconnect-From-Google-Account'}>
                                        <button onClick={disconnectFromGoogleAccount} className="nav-link sub-item">
                                            Disconnect From Google Account
                                        </button>
                                    </li> :
                                    <li className="nav-item" key={'Connect-with-Google-Account'}>
                                        <button onClick={connectWithGoogleAccount} className="nav-link sub-item">
                                            Connect with Google Account
                                        </button>
                                    </li>
                                    }
                                <li className="nav-item" key={'Choose-account'}>
                                    <NavLink to={'/choose-customer'} className="nav-link sub-item">
                                        Choose Account
                                    </NavLink>
                                </li>
                                <li className="nav-item" key={'download-script'}>
                                    <NavLink to={'/download-script'} className="nav-link sub-item" target={'blank'}>
                                        Download Script
                                    </NavLink>
                                </li>
                                 {/*))}*/}
                            </ul>
                        </div>
                    </>
                </li>
            </ul>
            {/*<div className="logout d-flex">*/}

            {/*    <Typography className={'nav-username'}>{authUser.user.name}<br/>*/}
            {/*        <span>Premium</span>*/}
            {/*    </Typography>*/}
            {/*    <Toast ref={toast}></Toast>*/}
            {/*    <Menu model={items} popup ref={menuLeft} id="nav-popup_menu_right" popupAlignment="right"/>*/}
            {/*    /!*<TieredMenu model={items} popup ref={menu}  breakpoint="767px" />*!/*/}
            {/*    <Button onClick={(event) => menuLeft.current.toggle(event)} className="mr-2 avatar-button"*/}
            {/*            aria-controls="popup_menu_right" aria-haspopup>*/}
            {/*        <Avatar label={authUser.user.name[0]} size="large"*/}
            {/*                style={{backgroundColor: 'rgba(5, 17, 242, 0.2)', color: 'rgba(5, 17, 242, 1)'}}*/}
            {/*                shape="circle"/>*/}
            {/*    </Button>*/}


            {/*</div>*/}
        </Sidebar>
        <Button className={'mobile-sidebar-toggle-button'} icon="pi pi-align-justify" onClick={() => setVisible(true)}/>
        <Modal isOpen={modal} toggle={toggle} >
            <ModalHeader toggle={toggle}>Connect To Google</ModalHeader>
            <ModalBody>
                <GoogleAccountConnectButton  />
            </ModalBody>
            <ModalFooter>
                {/*<Button color="primary" onClick={toggle}>*/}
                {/*    Do Something*/}
                {/*</Button>{' '}*/}
                <Button color="secondary" onClick={toggle}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    </div>
);
}

export default MobileSidebar;