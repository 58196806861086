import React from 'react';

const FilledCircule = ({ color ,className}) => (

<svg width="18" height="18" viewBox="0 0 18 18" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
    <circle cx="9" cy="9" r="9" fill="#898686"/>
</svg>


);

export default FilledCircule;