import React, {useEffect, useRef} from 'react'
import {Grid, Avatar, TextField, Button, Typography, Icon} from '@material-ui/core'
import {NavLink, useNavigate} from "react-router-dom";
import {useState} from 'react';
import axios from 'axios';

import google from "../../assets/images/google.svg";
import facebook from "../../assets/images/facebook.svg";
import logo from "../../assets/images/logo.png";
import {history} from "../../helpers/history";
import {useDispatch, useSelector} from "react-redux";
import {Toast} from "primereact/toast";
import {gapi} from "gapi-script";
import {authActions} from "../../redux";

const Register=()=>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
    const authError = useSelector(x => x.auth.error);
    const baseUrl = `${process.env.REACT_APP_API_URL}`;
    const clientId = `${process.env.REACT_APP_GOOGLE_CLIENT_ID}`;


    const toast = useRef(null);


        if (authUser) window.location.href = '/'



    const avatarStyle={backgroundColor:'#3370bd'}

    const [msg,setMsg] = useState('');
    const [username, setUsername] = useState("");

    const [user, setUser] = useState({
        email: "",
    });

    const {email} = user;
    const onInputChange = e => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    const signUp = () =>
    {

        const users = { username };  // To Store Email in Localstore and send to Home Page

        if(user.email === '')
        {
            alert('Email Field is empty')
            setMsg('Email Field is empty')
        }
        console.log(user.email)
        localStorage.setItem("email",user.email);
        axios.post(`${baseUrl}/v1/auth/sendVerificationCode`,user)
            .then((response) => {
                if(response.status === 200){
                    const email = user.email;
                    navigate(`/verification-code`, {state: {email}});
                }
            })
            .catch((err) => {
                if(err.response.status === 501){
                    toast.current.show({severity: 'error', summary: 'Error', detail: err.response.data.message});
                }else{
                    toast.current.show({severity: 'error', summary: 'Error', detail: 'There is an error in sending email'});
                }

                setMsg('Error in The Code');
            })
    }

    useEffect(() => {
        function start() {
            gapi.client.init({
                clientId: clientId,
                scope: 'profile email',
            });
        }

        gapi.load('client:auth2', start);
    }, []);

    const handleGoogleLogin = () => {
        const auth2 = gapi.auth2.getAuthInstance();
        auth2.signIn().then(googleUser => {
            const profile = googleUser.getBasicProfile();
            const id_token = googleUser.getAuthResponse().id_token;

            console.log('ID: ' + profile.getId());
            console.log('Name: ' + profile.getName());
            console.log('Image URL: ' + profile.getImageUrl());
            console.log('Email: ' + profile.getEmail());
            console.log('ID Token: ' + id_token);

            console.log(dispatch(authActions.loginWithGoogle({id_token}))) ;
            // Send the ID token to your backend for further processing
            // fetch(`${baseUrl}/v1/auth/google-login`, {
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json',
            //     },
            //     body: JSON.stringify({ token: id_token }),
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         console.log('Login successful:', data);
            //         // Handle successful login (e.g., save token, redirect user)
            //     })
            //     .catch(error => {
            //         console.error('Error during login:', error);
            //     });
        });
    };

    return(
        <Grid>
            <Toast ref={toast}/>
                <div className={'container logo-container logo-container-mobile'}>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <Grid align='left' className={'logo-mobile'}>
                                    <img src={logo} alt="Logo" className={'logo-image logo-image-mobile'} />



                                {/*<h4 style={{color:"green"}}>{msg}</h4>*/}
                            </Grid>
                        </div>
                        <div className={'col-md-6 right left-mobile'}>
                            <Typography className={'have-account-typography'}> Already Have Account ?
                                <NavLink to="/login">
                                    <span className={'login-header'}>Login</span>
                                </NavLink>
                            </Typography>
                        </div>
                    </div>
                </div>

                <div className={'container register-container'} >
                    <div className={'row'}>
                        <div className={'col-md-5'} >
                            <Typography className={'email-label'}> Email Address

                            </Typography>
                            <TextField
                                className={'email-input'}
                                name="email"
                                value={email}
                                onChange={e => onInputChange(e)}
                                placeholder='Enter You Email'
                                type='text'
                                fullWidth
                                variant="filled"
                                />

                            <Button
                                type='submit'
                                onClick={signUp}
                                color='primary'
                                variant="contained"
                                className={'email-verification-button'}
                                fullWidth>E-Mail Verification</Button>
                            <Typography className={'register-description-typography'}>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Cras dignissim pretium ipsum, non aliquet nulla auctor elementum.
                                Praesent sed leo vel leo fermentum mattis. In suscipit convallis mi,
                                in fringilla ante commodo ut. Aliquam egestas,
                                arcu at ultrices maximus, leo lacus tempus massa,
                                non placerat leo turpis eget mauris. Ut tincidunt aliquam vehicula.
                                Sed in mi non purus consectetur aliquam.
                                Pellentesque vel est elementum, vehicula ex egestas,
                                volutpat sapien.
                                <br/>
                                <NavLink to="/privacy-policy">
                                    <span className={'privacy-policy-link'}>Privacy Policy</span>
                                </NavLink>
                            </Typography>
                        </div>
                        <div className={'col-md-2'}>
                            <div className="d-flex vertical-line">
                                <div className="vr"></div>
                            </div>
                        </div>
                        <div className={'col-md-5'} >
                            <Typography variant="h1" className={'social-login-title'}>
                                Create A Free Account

                            </Typography>
                            <Typography variant="h1" className={'social-login-subtitle'}>
                                100% Free, For an unlimited time

                            </Typography>
                            <Button
                                type='submit'
                                onClick={handleGoogleLogin}
                                color='secondary'
                                variant='contained'
                                className={'google-sign-in-btn'}
                                fullWidth
                            >
                                <img src={google} alt="google" className={'google-icon'}/>
                                &nbsp;
                                Login with Google
                            </Button>
                            <Button
                                type='submit'
                                onClick={null}
                                color='secondary'
                                variant='contained'
                                className={'facebook-sign-in-btn'}
                                fullWidth
                            >
                                <img src={facebook} alt="google" className={'facebook-icon'} />
                                &nbsp;
                                Login with Facebook
                            </Button>
                        </div>
                    </div>
                </div>



        </Grid>
    )
}

export default Register